import { message } from 'antd';
import axios from 'axios';
import { ERROR_STATUS_TEXT } from '../constants/errStatusText';
import { baseURL } from '../constants/url';

axios.defaults.withCredentials = true;

export const login = async (credentials) => {
  try {
    const res = await axios.post(`${baseURL}/api/auth/login`, credentials);
    return res.data;
  } catch (e) {
    const errorStatusText = e.message;

    if (errorStatusText === ERROR_STATUS_TEXT.unauthorized) {
      message.error('Unauthorized - Username or password is incorrect');
    } else {
      message.error('Something went wrong');
    }
  }
};

export const check = async () => {
  const res = await axios.get(`${baseURL}/api/auth/check`);

  return res.data;
};

export const googleCheck = async (data) => {
  const res = await axios.post(`${baseURL}/api/auth/google/check`, data);
  return res.data;
};
